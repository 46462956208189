import { default as formsInit } from "./forms.js";


export default () => {

    const showAirports = (el) => {
        const text = el.querySelector('[data-open-text]').dataset.closeText;
        el.querySelector('span').innerText = text;
        el.querySelector('i').classList.add('rotate-90');

        $('.js-booking-airport-toggle').slideDown();
        sessionStorage.setItem('airport-options', true)
    }

    const hideAirports = (el) => {
        const text = el.querySelector('[data-close-text]').dataset.openText;
        el.querySelector('span').innerText = text;
        el.querySelector('i').classList.remove('rotate-90');

        $('.js-booking-airport-toggle').slideUp();
        sessionStorage.removeItem('airport-options')
    }

    // Togle new airports section open/close
    $('.js-booking-airport-toggle-action').on('click', function(e) {
        e.preventDefault();

        if (sessionStorage.getItem('airport-options')) {
            //Close
            hideAirports(e.currentTarget)
        }
        else {
            //Open
            showAirports(e.currentTarget)
        }
    })

    // Keep panel open if user has opened it once before
    if (sessionStorage.getItem('airport-options')) {
        const target = $('.js-booking-airport-toggle-action')[0];
        showAirports(target)
    }

    const formClass = ".js-booking-airport-selectors";

    if ($(`${formClass}:not(.validated-airports)`).length > 0) {
        const url = '/booking/select-flights/valid-airports';
        let loader = $('.m-booking-block__flights-select__loader');

        loader.show();

        $.ajax({
            type: 'POST',
            url: url,
            success: function(response) {
                if (response.trim() !== '') {
                    $(formClass).html(response);
                }
                loader.hide();
                formsInit();
            },
            error: function() {
                console.log('Error in Valid Airports POST request');
            }
        });
    }

}
